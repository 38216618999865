@import "../../vars.css";

.navbar {
    position: sticky;
    top: 0;
    background-color: var(--color-bg-transparent);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    z-index: 2;
    padding-left: 10%;
    padding-right: 10%;
}

.title {
    font-family: 'Playfair Display', serif;
    font-size: 34px;
    font-weight: 600;
    text-decoration: none;
    color: var(--color-text)
}

.menu {
    font-family: 'Playfair Display', serif;
    display: flex;
}

.menuBtn {
    display: none;
}

.menuItems {
    font-family: 'Raleway', serif;
    display: flex;
    gap: 47px;
    list-style: none;
}

.menuItems a {
    font-family: 'Raleway', serif;
    font-size: 25px;
    color: var(--color-text);
    text-decoration: none;
}


.menuItemsVertical {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    background: rgb(25, 55, 109);
    background: linear-gradient(0deg, rgba(25, 55, 109, 0.2) 0%, rgba(25, 55, 109, 1) 100%);
    padding: 24px 33px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 830px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .menu {
        position: absolute;
        right: 0;
        margin-right: 10%;
        flex-direction: column;
        align-items: flex-end;
        gap: 11px;
        z-index: 3;
    }

    .menuBtn {
        display: block;
        cursor: pointer;
    }

    .menuItems {
        display: none;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        border-radius: 10px;
        background: rgb(25, 55, 109);
        background: linear-gradient(0deg, rgba(25, 55, 109, 0.2) 0%, rgba(25, 55, 109, 1) 100%);
        padding: 24px 33px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    }

    .mainNav ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    .navItem {
        position: relative;
        padding: 10px 20px;
        cursor: pointer;
    }

    .subNav {
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #f0f0f0;
        display: none; /* Hide the sub-nav initially */
    }
    .navItem:hover .subNav {
        display: block; /* Show sub-nav when parent item is hovered */
    }

    .subNav li {
        padding: 10px 20px;
    }

    .menuOpen {
        display: flex;
        z-index: 1;
    }
}
