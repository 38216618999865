@import "../../vars.css";

.container {
    text-align: left;
    position: relative;
    /*background-color: rgba(12, 12, 12, 0.6);*/
    border-radius: 15px;
    padding: 73px;
    /*margin-top: 2%;*/
    z-index: 1;
    margin-left: 5%;
    margin-right: 5%;
}

.title {
    color: var(--color-text);
    text-align: center;
    font-size: 30px;
    font-weight: 700px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.subtitle {
    color: var(--color-text);
    text-align: center;
    font-size: 22px;
    font-weight: 700px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2%;
}

.contentfavShots, .contentTravel, .contentFood {
    padding-top: 2%;
    padding-bottom: 2%;
}

.photoGrid {
    padding-top: 2%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto; 
    gap: 8px; 
}

.photoItem {
    width: 100%;
    transition: transform 0.3s ease-in-out;
    border-radius: 3%;
}

.photoItem:hover {
    transform: scale(1.05); 
    border: 5px solid white;
  }

  
@media screen and (max-width: 830px) {
    .container {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .content {
        flex-direction: column;
    }

    .photoGrid {
        padding-top: 2%;
        display: grid;
        text-align: center;
        grid-template-columns: auto;
        grid-template-rows: auto; 
    }
    .photoItem {
        width: 75%;
        border-radius: 3%;
    }

}