@import "../../vars.css";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Raleway:wght@400;500;700&display=swap');


.container {
    position: relative;
    display: flex;
    font-family: 'Playfair Display', serif;
    align-items: center;
    justify-content: space-between;
    margin-top: 49px;
    z-index: 1;
    margin-left: 10%;
    margin-right: 10%;
    flex-direction: column;
}

.intro {
    position: relative;
    display: flex;
    font-family: 'Playfair Display', serif;
    align-items: center;
    justify-content: space-between;
    margin-top: 49px;
    z-index: 1;
    margin-left: 10%;
    margin-right: 10%;
    flex-direction: row;
}

.content {
    width: 50%;
    display: flex;
    font-family: 'Raleway', serif;
    flex-direction: column;
    text-align: center;
    align-items: center;
    color: var(--color-text);
    z-index: 1;
}

.sub {
    text-align: left;
}


.title {
    font-size: 47px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 33px;
    font-family: 'Playfair Display', serif;
    background: var(--color-text);
    -webkit-text-fill-color: transparent;
    background-clip: initial;
    -webkit-background-clip: text;
    
    position: relative;
    white-space: nowrap;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0); 
    animation: typing 2s steps(15, end) forwards, blink 1s 30; 
}

@keyframes typing {
    0% {
        width: 0%;
    }

    50% {
        width: 50%;
    }

    100% {
        width: 100%;
    }
}

@keyframes blink {
    0%, 100% {
        border-right: 2px solid transparent;
    }

    50% {
        border-right: 2px solid #222;
    }
}

.subtitle {
    font-size: 25px;
    font-weight: 275;
    text-align: left;
    margin-bottom: 33px;
    font-family: 'Raleway', serif;
    background: var(--color-text);
    -webkit-text-fill-color: transparent;
    background-clip: initial;
    -webkit-background-clip: text;
}


.description {
    font-size: 28px;
    font-family: 'Raleway', serif;
    margin-right: 10%;
    margin-bottom: 52px;

}

.contactBtn {
    text-decoration: none;
    font-family: 'Raleway', serif;
    background-color: var(--color-primary);
    color: var(--color-text);
    border-radius: 100px;
    font-size: 22px;
    font-weight: 600;
    /* margin-right: 80%; */
    padding: 10px 22px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.contactBtn:hover {
    background-color: var(--color-secondary);
    color: var(--color-text);
}

.meImg {
    width: 45%;
    border-radius: 50%;
    z-index: 1;
    margin-top: 1%;

    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 5px);
    }

    100% {
        transform: translate(0, -0px);
    }
}

.topBlur {
    position: absolute;
    width: 50vw;
    height: 50vw;
    min-width: 350px;
    min-height: 350px;
    top: -128px;
    left: 10vw;
    border-radius: 764px;
    background: rgba(25, 55, 109, 0.7);
    filter: blur(100px);
    z-index: 0;
}

.bottomBlur {
    position: absolute;
    width: 70vw;
    height: 50vw;
    min-width: 250px;
    min-height: 350px;
    top: 246px;
    right: -25vw;
    border-radius: 764px;
    background: rgba(25, 55, 109, 0.7);
    filter: blur(100px);
    z-index: 0;
}

@media screen and (max-width: 830px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .content {
        text-align: center;
        align-items: center;
        width: 80%;
    }

    .intro {
        flex-direction: column-reverse;
        align-items: center;
    }

    .meImg {
        width: 75%;
    }

    .sub {
        text-align: center;
    }

    .title {
        padding-top: 10%;
        width: 80%;
        font-size: 38px;
        text-align: center;

    }

    .subtitle {
        font-size: 22px;
        text-align: center;
    }

    .description {
        font-size: 22px;
    }

    .contactBtn {
        margin-right: 0%;
    }
}