@import "../../vars.css";

.container {
    text-align: left;
    position: relative;
    background-color: rgba(12, 12, 12, 0.6);
    border-radius: 15px;
    padding: 2%;
    margin-top: 129px;
    z-index: 1;
    margin-left: 5%;
    margin-right: 5%;
}

.title {
    color: var(--color-text);
    text-align: center;
    font-size: 30px;
    font-weight: 700px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

h4 {
    font-family: 'Playfair Display', serif;
    font-size: 22px;
}

.content {
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.meImg {
    width: 40%;
    border-radius: 50%;
    margin-right: 5%;
    margin-top: 3%;
}

.aboutIcons {
    margin-left: 5%;
    width: 10%;
    height: 10%;
}

.aboutItems {
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.aboutItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    list-style: none;
    padding: 1%;
    background-image: linear-gradient(
        90deg, 
        rgba(165, 215, 232, 0.42) 0%, 
        rgba(255, 255, 255, 0) 100%
    );
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s
}

.aboutItem:hover {
    background-size: 100% 100%;
}

.aboutItem h3 {
    font-size: 25px;
    font-weight: 600;
}

.aboutItem p {
    font-size: 20px;

}

.aboutItemText {
    margin-left: 2%;
}

.aboutItem a, .aboutItem a:visited{
    color: var(--color-text);
    background-color: transparent;
}

.aboutItem a:hover {
    color: var(--color-primary);
    background-color: transparent;
}

@media screen and (max-width: 830px) {
    .container {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .content {
        flex-direction: column;
    }

    .meImg {
        display: none;
    }

    .aboutItems {
        margin-top: 25px;
    }

    .aboutItem {
        padding-left: 0;
        padding-right: 0;
    }

    .aboutIcons {
        display: none;
    }

}