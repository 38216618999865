@import "./vars.css";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Raleway:wght@400;500;700&display=swap');


.App {
  text-align: center;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  background-color: var(--color-bg);
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

h1, h2, h3 {
  font-family: 'Playfair Display', serif;
}

body, p, h4, nav {
  font-family: 'Raleway', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Headshot-Photo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 50%;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
      transform: translate(0, 0px);
  }

  50% {
      transform: translate(0, 10px);
  }

  100% {
      transform: translate(0, -0px);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link, .link, .link:visited {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
