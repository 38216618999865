@import "../../vars.css";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Raleway:wght@400;500;700&display=swap');

.container {
    text-align: left;
    position: relative;
    /*background-color: rgba(12, 12, 12, 0.6);*/
    border-radius: 15px;
    padding: 2%;
    z-index: 1;
    margin-left: 5%;
    margin-right: 5%;
}

.title {
    color: var(--color-text);
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.content {
    margin-top: 2%;
    display: flex;
    background-color: rgba(12, 12, 12, 0.6);
    flex-direction: row;
    align-items: center;
}

.experienceIcons {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    margin-left: 4%;
}

.experienceIconsNoBorder {
    width: 50%;
    height: 50%;
    margin-left: 4%;
}

.experienceIconsExc {
    width: 100%;
    margin-left: 4%;
}

.experienceIconsSmall {
    width: 30%;
    height: 30%;
    border-radius: 50%;
    margin-left: 4%;
}

.otherIcon {
    width: 20%;
    height: 20%;
    border-radius: 50%;
    margin-left: 4%;
}

.bbpIcon {
    width: 15%;
    height: 15%;
    padding-top: 1%;
}

.mbaaIcon {
    width: 30%;
    height: 30%;
    padding-top: 1%;
}

.bbpText {
    margin-left: 2%;
}

.experienceItems {
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.experienceItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 20px;
    list-style: none;
    padding: 1%;
    background-image: linear-gradient(
        90deg, 
        rgba(165, 215, 232, 0.42) 0%, 
        rgba(255, 255, 255, 0) 100%
    );
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s
}

.experienceItem:hover {
    background-size: 100% 100%;
}

.experienceItem h3 {
    font-size: 25px;
    font-weight: 600;
}

.experienceItem h4 {
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
}

.experienceItem p {
    font-size: 20px;

}

.experienceText {
    margin-left: 2%;
}

.experienceItem a, .experienceItem a:visited{
    color: var(--color-text);
    background-color: transparent;
}

.experienceItem a:hover {
    color: var(--color-primary);
    background-color: transparent;
}

.experiencePhotoGrid {
    display: grid;
    grid-template-columns: repeat(3, [col-start] 50% [col-start]);
    justify-items: center;
    align-items: center;
}

.experiencePhotoGrid img {
    width: 60%;
}

.experiencePhoto {
    border-radius: 10%;
}

.otherExperiencePhoto {
    border-radius: 2%;
}

.clubPhoto {
    width: 25%;
}

.descImg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /*justify-items: center;*/
    /*align-items: center;*/
}

.descImg p {
    width: 120%;
}

.descImg img {
    margin-left: 35%;
    width: 30%;
}

@media screen and (max-width: 830px) {
    .container {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .content {
        flex-direction: column;
    }

    .experienceItems {
        margin-top: 25px;
    }

    .experienceItem {
        padding-left: 0;
        padding-right: 0;
    }

    .experienceIcons, .bbpIcon , .mbaaIcon, .experienceIconsSmall, .otherIcon, .experienceIconsExc, .experienceIconsNoBorder{
        display: none;
    }

    .descImg p {
        width: 180%;
    }

    .experiencePhoto {
        display: none;
    }

    .experiencePhotoGrid {
        display: none;
    }
}