@import "../../vars.css";

.outside {
    /* background-color: rgba(12, 12, 12, 0.6); */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    z-index: 2;
}

.footerInfo{
    font-size: 16px;
    font-family: 'Raleway', serif;
    color: var(--color-text);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.menuItems {
    display: flex;
    list-style: none;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
    background: rgb(25, 55, 109);
    background: linear-gradient(0deg, rgba(25, 55, 109, 0.2) 0%, rgba(25, 55, 109, 1) 100%);
    padding: 24px 33px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}


.menuIcon {
    /* font-size: 22px;
    font-family: 'Playfair Display', serif;
    color: var(--color-text);
    text-decoration: none; */
    width: 20%;
}

.LIIcon {
    width: 25%;
}

.EmailIcon {
    width: 40%;
}


.menuIcon:hover, .LIIcon:hover, .EmailIcon:hover {
    /* color: var(--color-primary);
    text-decoration: underline; */
    transform: scale(1.05); 
}

