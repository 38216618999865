* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", Arial, Helvetica, sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}