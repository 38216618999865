:root {
    /* COLORS */
    --color-text: #fff;
    --color-primary: #576cbc;
    --color-secondary: #19376d;
    --color-dark: #0b2447;
    --color-bg: #04152d;
    --color-bg-transparent: rgba(4, 21, 45, 0.6);

    /* FONTS */
    --font-roboto: "Roboto";
    --font-verdana: "Verdana";
    --font-geneva: "Geneva";
    --font-tahoma: "Tahoma"
    --font-sans: "sans-serif";
}