@import "../../vars.css";

.container {
    text-align: left;
    position: relative;
    /*background-color: rgba(12, 12, 12, 0.6);*/
    border-radius: 15px;
    padding: 73px;
    /*margin-top: 129px;*/
    z-index: 1;
    margin-left: 5%;
    margin-right: 5%;
}

.title {
    color: var(--color-text);
    text-align: center;
    font-size: 30px;
    font-weight: 700px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.subtitle {
    color: var(--color-text);
    text-align: center;
    font-size: 22px;
    font-weight: 700px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2%;
}

.projectGrid {
    padding-top: 2%;
    padding-bottom: 2%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto; 
    gap: 20px; 
}

.projectLink {
    color: inherit;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.projectItem {
    transition: transform 0.2s;
    border-radius: 3%;
    color: var(--color-text);
    text-align: center;
    padding: 20px;
    width: 100%;
}

.projectItem:hover {
    transform: scale(1.05);
}


.projectItem h4 {
    font-family: 'Playfair Display', serif;
    font-size: 16px;
}

.projectImage {
    width: 90%;
    border-radius: 10%;
}

.projectImageLogo {
    width: 63%;
    border-radius: 50%;
}
.projectLogo {
    width: 50%;
    border-radius: 10%;
}

.CxCImage {
    width: 100%;
    border-radius: 10%;
}

  
@media screen and (max-width: 830px) {
    .container {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    .content {
        flex-direction: column;
        text-align: center;
    }

    .projectLink {
        padding-left:0%;
        margin-left: 20%;
        padding-right: 0%;
        margin-right: 0%;
        justify-content: center;
        justify-items: center;
        text-align: center;
    }

    .projectGrid {
        padding-top: 2%;
        padding-bottom: 2%;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto; 
        gap: 50px;
        justify-content: center;
        justify-items: center;
        align-items: center;
    }

    .projectItem {
        width: 75%;
        border-radius: 3%;
    }

}